import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import jstat from 'jstat';

const PosteriorDistribution = ({ variants }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || !entries.length) return;

      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height: Math.max(300, height) });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!variants || variants.length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 30, bottom: 40, left: 50 };
    const width = dimensions.width;
    const height = dimensions.height;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Calculate distributions and their 1st and 90th percentiles
    const calculateDistribution = (variant, points = 1000) => {
      const alpha = variant.conversions + 1;
      const beta = variant.visitors - variant.conversions + 1;
      const distribution = Array.from({ length: points }, (_, i) => {
        const x = i / (points - 1);
        return { x, y: jstat.beta.pdf(x, alpha, beta) };
      });
      const percentile1 = jstat.beta.inv(0.01, alpha, beta);
      const percentile90 = jstat.beta.inv(0.90, alpha, beta);
      return { distribution, percentile1, percentile90 };
    };

    const distributionsWithPercentiles = variants.map(v => calculateDistribution(v));

    // Find the minimum 1st percentile and maximum 90th percentile
    const minPercentile1 = Math.min(...distributionsWithPercentiles.map(d => d.percentile1));
    const maxPercentile90 = Math.max(...distributionsWithPercentiles.map(d => d.percentile90));

    // Calculate the padding (10% of the range)
    const padding = (maxPercentile90 - minPercentile1) * 0.1;

    // Set the x-axis range
    const xAxisMin = Math.max(0, minPercentile1 - padding);
    const xAxisMax = maxPercentile90 + padding;

    const x = d3.scaleLinear()
      .domain([xAxisMin, xAxisMax])
      .range([0, innerWidth]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(distributionsWithPercentiles.flatMap(d => d.distribution.map(point => point.y)))])
      .range([innerHeight, 0]);

    const area = d3.area()
      .x(d => x(d.x))
      .y0(innerHeight)
      .y1(d => y(d.y))
      .curve(d3.curveBasis);

    svg.attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const colors = ['#00bdff', '#8cf7ba', '#ff78e8', '#1215e', '#414042', '#e6e7e8'];

    distributionsWithPercentiles.forEach((dist, index) => {
      g.append("path")
        .datum(dist.distribution)
        .attr("fill", colors[index % colors.length])
        .attr("fill-opacity", 0.3)
        .attr("d", area);
    });

    g.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x).ticks(width > 500 ? 5 : 3).tickFormat(d3.format(".1%")));

    g.append("g")
      .call(d3.axisLeft(y).ticks(height > 300 ? 5 : 3));

    g.append("text")
      .attr("text-anchor", "middle")
      .attr("x", innerWidth / 2)
      .attr("y", innerHeight + margin.top + 20)
      .text("Conversion Rate")
      .style("font-size", "12px");

    g.append("text")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left + 20)
      .attr("x", -innerHeight / 2)
      .text("Density")
      .style("font-size", "12px");

    // Add text for percentile range
    g.append("text")
      .attr("text-anchor", "end")
      .attr("x", innerWidth)
      .attr("y", -5)
      .attr("font-size", "10px")
      .text(`Range: ${(xAxisMin * 100).toFixed(2)}% - ${(xAxisMax * 100).toFixed(2)}%`);

    // Responsive legend
    const legendItemHeight = 20;
    const legendItemsPerRow = Math.floor(innerWidth / 150);  // Adjust 150 based on your needs
    const legend = g.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("text-anchor", "start")
      .selectAll("g")
      .data(variants)
      .enter().append("g")
      .attr("transform", (d, i) => `translate(${(i % legendItemsPerRow) * 150},${Math.floor(i / legendItemsPerRow) * legendItemHeight})`);

    legend.append("rect")
      .attr("x", 0)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", (d, i) => colors[i % colors.length]);

    legend.append("text")
      .attr("x", 24)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text(d => d.name);

  }, [variants, dimensions]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', minHeight: '300px' }}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default PosteriorDistribution;