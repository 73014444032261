import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 md:py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <h1 className="text-xl md:text-2xl font-bold text-neutral-black mb-4 md:mb-0">
            <Link to="/" className="hover:text-sky-blue transition-colors">Testing Haven</Link>
          </h1>
        </div>
      </div>
      <div className="bg-sky-blue">
        <nav className="container mx-auto px-4 py-2 flex justify-center md:justify-start space-x-4">
          <Link 
            to="/ab-calculator" 
            className="text-white hover:text-calm-green transition-colors text-sm md:text-base"
          >
            A/B Calculator
          </Link>
          <Link 
            to="/power-analysis" 
            className="text-white hover:text-calm-green transition-colors text-sm md:text-base"
          >
            Power Analysis
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;