import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/Card';
import { Input } from '@/components/ui/Input';
import { Button } from '@/components/ui/power-analysis/Button';
import { Info, Plus } from 'lucide-react';
import PosteriorDistribution from '@/components/ui/calculator/PosteriorDistribution';
import { calculateBayesianProbabilities, calculatePosteriorDistribution } from '@/lib/abTestUtils';

const BoxWhiskerPlot = ({ data, width, height, confidenceLevel }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous render

    const margin = { top: 40, right: 40, bottom: 60, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.ci99High) * 1.1])
      .range([innerHeight, 0]);

    const x = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([0, innerWidth])
      .padding(0.2);

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Y-axis
    g.append("g")
      .call(d3.axisLeft(y).tickFormat(d => `${(d * 100).toFixed(1)}%`));

    // X-axis
    g.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("y", 10)
      .attr("x", -5)
      .attr("dy", ".35em")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Box and whisker plots
    const boxWidth = x.bandwidth();

    const boxGroups = g.selectAll(".box")
      .data(data)
      .enter().append("g")
      .attr("class", "box")
      .attr("transform", d => `translate(${x(d.name)},0)`);

    // Whiskers (99% CI)
    boxGroups.append("line")
      .attr("x1", boxWidth / 2)
      .attr("x2", boxWidth / 2)
      .attr("y1", d => y(d.ci99Low))
      .attr("y2", d => y(d.ci99High))
      .attr("stroke", d => d.color)
      .attr("stroke-width", 1);

    // Boxes (95% and 90% CI)
    boxGroups.append("rect")
      .attr("x", boxWidth * 0.15)
      .attr("width", boxWidth * 0.7)
      .attr("y", d => y(d.ci95High))
      .attr("height", d => y(d.ci95Low) - y(d.ci95High))
      .attr("fill", d => d.color)
      .attr("fill-opacity", 0.3);

    boxGroups.append("rect")
      .attr("x", boxWidth * 0.15)
      .attr("width", boxWidth * 0.7)
      .attr("y", d => y(d.ci90High))
      .attr("height", d => y(d.ci90Low) - y(d.ci90High))
      .attr("fill", d => d.color)
      .attr("fill-opacity", 0.5);

    // Median line
    boxGroups.append("line")
      .attr("x1", 0)
      .attr("x2", boxWidth)
      .attr("y1", d => y(d.rate))
      .attr("y2", d => y(d.rate))
      .attr("stroke", d => d.color)
      .attr("stroke-width", 2);

    // Conversion rate labels
    boxGroups.append("text")
      .attr("x", boxWidth / 2)
      .attr("y", d => y(d.rate) - 10)
      .attr("text-anchor", "middle")
      .attr("fill", d => d.color)
      .attr("font-weight", "bold")
      .text(d => `${(d.rate * 100).toFixed(2)}%`);

    // Y-axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Conversion Rate");

    // Confidence level text
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height - 5)
      .attr("text-anchor", "middle")
      .text(`Confidence Level: ${confidenceLevel}%`);

  }, [data, width, height, confidenceLevel]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};

const ABCalculator = () => {
  const [control, setControl] = useState({ visitors: '', conversions: '' });
  const [variants, setVariants] = useState([{ name: 'Variant A', visitors: '', conversions: '' }]);
  const [results, setResults] = useState(null);
  const [bayesianComparison, setBayesianComparison] = useState(null);
  const [confidenceLevel, setConfidenceLevel] = useState(90);


  const colors = ['#00bdff', '#8cf7ba', '#ff78e8', '#1215e', '#414042', '#e6e7e8'];

  const handleInputChange = (index, field, value) => {
    if (index === -1) {
      setControl({ ...control, [field]: value });
    } else {
      const newVariants = [...variants];
      newVariants[index] = { ...newVariants[index], [field]: value };
      setVariants(newVariants);
    }
  };

  
  const addVariant = () => {
    setVariants([...variants, { name: `Variant ${String.fromCharCode(65 + variants.length)}`, visitors: '', conversions: '' }]);
  };

  const calculateResults = () => {
    const controlVisitors = Number(control.visitors);
    const controlConversions = Number(control.conversions);
    const controlRate = controlVisitors ? (controlConversions / controlVisitors) : 0;

    const allVariants = [
      { name: 'Control', visitors: controlVisitors, conversions: controlConversions },
      ...variants.map(v => ({ ...v, visitors: Number(v.visitors), conversions: Number(v.conversions) }))
    ];

    const bayesianResults = calculateBayesianProbabilities(allVariants);
    const posteriorDistributions = bayesianResults.map(v => ({
      ...v,
      distribution: calculatePosteriorDistribution(v)
    }));

    const newResults = variants.map((variant, index) => {
      const variantRate = variant.visitors ? (variant.conversions / variant.visitors) : 0;
      const lift = ((variantRate - controlRate) / controlRate) * 100;
      
      // Calculate p-value and power (keep existing implementation)
      const pooledProportion = (controlConversions + variant.conversions) / (controlVisitors + variant.visitors);
      const standardError = Math.sqrt(pooledProportion * (1 - pooledProportion) * (1/controlVisitors + 1/variant.visitors));
      const zScore = Math.abs((variantRate - controlRate) / standardError);
      const pValue = 2 * (1 - normCDF(zScore));  // Two-tailed test

      const alpha = 0.05; // Significance level
      const beta = 1 - normCDF(zScore - normCDF(1 - alpha/2)) + normCDF(-zScore - normCDF(1 - alpha/2));
      const power = (1 - beta) * 100;

      return {
        name: variant.name,
        controlRate: (controlRate * 100).toFixed(2),
        variantRate: (variantRate * 100).toFixed(2),
        lift: lift.toFixed(2),
        pValue: pValue.toFixed(4),
        power: power.toFixed(2),
        significant: pValue <= 0.05,
        bayesianProbability: (posteriorDistributions[index + 1].probability * 100).toFixed(2),
        visitors: variant.visitors,
        conversions: variant.conversions
      };
    });

    setResults(newResults);
    setBayesianComparison(posteriorDistributions);
  };

  const normCDF = (x) => {
    const t = 1 / (1 + 0.2316419 * Math.abs(x));
    const d = 0.3989423 * Math.exp(-x * x / 2);
    const p = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    return x > 0 ? 1 - p : p;
  };

  const calculateConfidenceInterval = (rate, visitors, level) => {
    const z = {90: 1.645, 95: 1.96, 99: 2.576}[level];
    const margin = z * Math.sqrt((rate * (1 - rate)) / visitors);
    return [Math.max(0, rate - margin), Math.min(1, rate + margin)];
  };

  const FrequentistChart = ({ results, confidenceLevel, control }) => {
    const data = [
      { 
        name: 'Control', 
        rate: Number(control.conversions) / Number(control.visitors),
        visitors: Number(control.visitors),
        color: '#00bdff'
      },
      ...results.map(result => ({
        name: result.name,
        rate: Number(result.variantRate) / 100,
        visitors: Number(result.visitors),
        color: result.name === 'Variant A' ? '#8cf7ba' : '#ff78e8'
      }))
    ].map(item => {
      const [ci90Low, ci90High] = calculateConfidenceInterval(item.rate, item.visitors, 90);
      const [ci95Low, ci95High] = calculateConfidenceInterval(item.rate, item.visitors, 95);
      const [ci99Low, ci99High] = calculateConfidenceInterval(item.rate, item.visitors, 99);
      return {
        ...item,
        ci90Low, ci90High,
        ci95Low, ci95High,
        ci99Low, ci99High
      };
    });

    return (
      <BoxWhiskerPlot 
        data={data} 
        width={800} 
        height={400} 
        confidenceLevel={confidenceLevel} 
      />
    );
  };

  const BayesianChart = ({ bayesianComparison }) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={bayesianComparison} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={t => `${t * 100}%`} />
          <Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} />
          <Legend />
          <Bar dataKey="probability" name="Probability of Being Best">
            {bayesianComparison.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
            <LabelList dataKey="probability" position="top" formatter={(value) => `${(value * 100).toFixed(2)}%`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const Tooltip = ({ children, explanation }) => (
    <div className="group relative inline-block">
      {children}
      <div className="invisible group-hover:visible absolute z-10 w-64 p-2 mt-2 text-sm bg-gray-100 rounded-lg shadow-lg">
        {explanation}
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 text-sky-blue">A/B Test Result Calculator</h2>
      
      {/* Input Section */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="text-xl md:text-2xl">Test Data Input</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Control Group */}
            <div>
              <h4 className="text-lg font-semibold mb-2 text-neutral-black">Control Group</h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Input
                  type="number"
                  placeholder="Visitors"
                  value={control.visitors}
                  onChange={(e) => handleInputChange(-1, 'visitors', e.target.value)}
                  className="w-full"
                />
                <Input
                  type="number"
                  placeholder="Conversions"
                  value={control.conversions}
                  onChange={(e) => handleInputChange(-1, 'conversions', e.target.value)}
                  className="w-full"
                />
              </div>
            </div>

            {/* Variant Groups */}
            {variants.map((variant, index) => (
              <div key={index}>
                <h4 className="text-lg font-semibold mb-2 text-neutral-black">{variant.name}</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <Input
                    type="number"
                    placeholder="Visitors"
                    value={variant.visitors}
                    onChange={(e) => handleInputChange(index, 'visitors', e.target.value)}
                    className="w-full"
                  />
                  <Input
                    type="number"
                    placeholder="Conversions"
                    value={variant.conversions}
                    onChange={(e) => handleInputChange(index, 'conversions', e.target.value)}
                    className="w-full"
                  />
                </div>
              </div>
            ))}

            <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between space-y-4 sm:space-y-0 sm:space-x-4 w-full">
              <div className="sm:w-1/3">
                < Button 
                  onClick={addVariant} 
                  className="bg-soft-gray text-neutral-black hover:bg-gray-300 px-3 py-2 rounded text-sm flex items-center justify-center sm:justify-start w-full sm:w-auto"
                >
                  <Plus className="w-4 h-4 mr-2" /> Add Variant
                </Button>
              </div>
               <Button onClick={calculateResults} className="bg-sky-blue text-white px-4 py-2 rounded">
                Calculate Results
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Confidence Level Selector */}
      <Card className="mb-8">
        <CardContent>
          <label className="block text-sm font-medium text-gray-700 mb-2">Confidence Level</label>
          <select
            value={confidenceLevel}
            onChange={(e) => setConfidenceLevel(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value={90}>90%</option>
            <option value={95}>95%</option>
            <option value={99}>99%</option>
          </select>
        </CardContent>
      </Card>
      
      {/* Results Section */}
      {results && (
        <div className="mt-8 space-y-8">
          <h3 className="text-xl md:text-2xl font-bold mb-6 text-sky-blue">Test Results</h3>

          {/* Frequentist Results */}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg md:text-xl">Frequentist Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                {results.map((result, index) => (
                  <div key={index} className="p-4 border rounded-lg">
                    <h5 className="text-lg font-semibold text-neutral-black mb-2">{result.name}</h5>
                    <p className="text-neutral-black">Control Conversion Rate: {result.controlRate}%</p>
                    <p className="text-neutral-black">Variant Conversion Rate: {result.variantRate}%</p>
                    <p className="text-neutral-black">Measured Lift: {result.lift}%</p>
                    <p className="text-neutral-black">Calculated p-value: {result.pValue}</p>
                    <p className="text-neutral-black">Statistical Power: {result.power}%</p>
                    <p className="font-bold text-neutral-black">
                      Statistical Significance: 
                      <span className={result.significant ? 'text-calm-green' : 'text-chill-pink'}>
                        {result.significant ? ' Significant' : ' Not Significant'}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Frequentist Chart */}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg md:text-xl">Conversion Rate Comparison</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[400px] md:h-[500px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={[
                    { name: 'Control', rate: Number(results[0].controlRate) },
                    ...results.map(r => ({ name: r.name, rate: Number(r.variantRate) }))
                  ]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={t => `${t}%`} />
                    <Tooltip formatter={value => `${value}%`} />
                    <Legend />
                    <Bar dataKey="rate" fill="#8884d8">
                      <LabelList dataKey="rate" position="top" formatter={v => `${v}%`} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          {/* Bayesian Results */}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg md:text-xl">Bayesian Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <p className="text-neutral-black">
                    Control Probability of Being Best: {(bayesianComparison[0].probability * 100).toFixed(2)}%
                  </p>
                </div>
                {results.map((result, index) => (
                  <div key={index}>
                    <p className="text-neutral-black">
                      {result.name} Probability of Being Best: {result.bayesianProbability}%
                    </p>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      
      {/* Bayesian Comparison of All Variants */}
      {bayesianComparison && (
        <Card className="mt-8">
          <CardHeader>
            <CardTitle className="text-lg md:text-xl">Bayesian Comparison of All Variants</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px] md:h-[400px]">
              <BayesianChart bayesianComparison={bayesianComparison} />
            </div>
          </CardContent>
        </Card>
      )}

      {/* Posterior Distributions */}
      {bayesianComparison && (
        <Card className="mt-8">
        <CardHeader>
          <CardTitle className="text-lg md:text-xl">Posterior Distributions</CardTitle>
        </CardHeader>
        <CardContent>
          <div style={{ height: '400px', width: '100%' }}>
            <PosteriorDistribution variants={bayesianComparison} />
          </div>
        </CardContent>
      </Card>
      )}


      {/* Explanation Card */}
      <Card className="mt-8">
        <CardHeader>
          <CardTitle className="flex items-center text-lg md:text-xl">
            <Info className="mr-2" />
            Understanding A/B Test Results
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-2">A/B testing helps you make data-driven decisions. Here's what the key terms mean:</p>
          <ul className="list-disc list-inside space-y-2">
            <li><strong>Conversion Rate:</strong> The percentage of visitors who complete a desired action.</li>
            <li><strong>Lift:</strong> The percentage increase or decrease in the conversion rate of a variant compared to the control.</li>
            <li><strong>p-value:</strong> The probability that the observed results could have occurred by chance. Lower values indicate stronger evidence against the null hypothesis. (p-value*100) is the calculated probability of falsely rejecting the null hypothesis.</li>
            <li><strong>Statistical Significance:</strong> Typically, results are considered statistically significant if the p-value is less than 0.1  (10%).</li>
            <li><strong>Statistical Power:</strong> The probability of detecting a true effect if it exists. Higher power reduces the chance of a false negative.</li>
            <li><strong>Bayesian Probability:</strong> The probability that a variant is truly better than the control, based on the observed data and prior beliefs.</li>
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};

export default ABCalculator;