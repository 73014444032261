import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const D3LineChart = ({ data }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || !entries.length) return;

      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height: Math.max(300, height) });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!data || data.length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    const margin = { top: 20, right: 30, bottom: 50, left: 60 };
    const width = dimensions.width;
    const height = dimensions.height;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.mde)])
      .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
      .domain([0, 2000000])  // Set fixed maximum to 2,000,000
      .range([innerHeight, 0]);

    // Line generator
    const line = d3.line()
      .x(d => xScale(d.mde))
      .y(d => yScale(d.sampleSize));

    // Draw the line
    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#8884d8')
      .attr('stroke-width', 2)
      .attr('d', line);

    svg.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale)
        .ticks(width > 400 ? 10 : 5)
        .tickFormat(d => `${d}%`));

    svg.append('g')
      .call(d3.axisLeft(yScale)
        .ticks(height > 300 ? 5 : 3)
        .tickFormat(d => {
          if (d >= 1000000) return `${d / 1000000}M`;
          if (d >= 1000) return `${d / 1000}K`;
          return d;
        }));

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', innerWidth / 2)
      .attr('y', height - margin.bottom / 2)
      .style('font-size', '0.8em')
      .text('Minimum Detectable Effect (%)');

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('y', -margin.left + 20)
      .attr('x', -innerHeight / 2)
      .style('font-size', '0.8em')
      .text('Sample Size');

    const tooltip = d3.select(containerRef.current).append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '10px');

    const formatNumber = d3.format(',');

    svg.selectAll('circle')
      .data(data)
      .enter().append('circle')
      .attr('r', 4)
      .attr('cx', d => xScale(d.mde))
      .attr('cy', d => yScale(d.sampleSize))
      .attr('fill', '#8884d8')
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', .9);
        tooltip.html(`MDE: ${d.mde}%<br/>Sample Size: ${formatNumber(Math.round(d.sampleSize))}`)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

  }, [data, dimensions]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', minHeight: '300px' }}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default D3LineChart;