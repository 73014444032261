import React, { useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/power-analysis/Tabs';
import { Slider } from '@/components/ui/power-analysis/Slider';
import { Input } from '@/components/ui/Input';
import { Button } from '@/components/ui/power-analysis/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Area } from 'recharts';
import { Info } from 'lucide-react';
import D3LineChart from '@/components/ui/power-analysis/Linechart';  // Updated import

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PowerAnalysis = () => {
  const [mode, setMode] = useState('sampleSize');
  const [baselineConversionRate, setBaselineConversionRate] = useState(10);
  const [minimumDetectableEffect, setMinimumDetectableEffect] = useState(5);
  const [statisticalSignificance, setStatisticalSignificance] = useState(95);
  const [statisticalPower, setStatisticalPower] = useState(80);
  const [sampleSize, setSampleSize] = useState(1000);
  const [calculatedPValue, setCalculatedPValue] = useState(0);
  const [calculatedPower, setCalculatedPower] = useState(0);

  const calculateSampleSize = () => {
    const alpha = 1 - statisticalSignificance / 100;
  const beta = 1 - statisticalPower / 100;
  const p1 = baselineConversionRate / 100;
  const p2 = p1 * (1 + minimumDetectableEffect / 100);  // Changed to relative
  const pBar = (p1 + p2) / 2;
  const zAlpha = -0.862 + sqrt(0.743 - 2.404 * Math.log(alpha));
  const zBeta = -0.862 + sqrt(0.743 - 2.404 * Math.log(beta));
  const calculatedSampleSize = Math.ceil(
    (Math.pow(zAlpha + zBeta, 2) * pBar * (1 - pBar) * 2) /
    Math.pow(p2 - p1, 2)
  );
    setSampleSize(calculatedSampleSize);
  };

  const calculateSignificanceAndPower = () => {
    const p1 = baselineConversionRate / 100;
    const p2 = p1 * (1 + minimumDetectableEffect / 100);  // Changed to relative
    const pBar = (p1 + p2) / 2;
    const se = Math.sqrt((2 * pBar * (1 - pBar)) / sampleSize);
    const z = (p2 - p1) / se;
    const calculatedSignificance = 1 - normalCDF(z);
    const calculatedPowerValue = 1 - normalCDF(1.96 - z) + normalCDF(-1.96 - z);
    setCalculatedPValue(calculatedSignificance.toFixed(4));
    setCalculatedPower((calculatedPowerValue * 100).toFixed(2));
  };

  const normalCDF = (x) => {
    const t = 1 / (1 + 0.2316419 * Math.abs(x));
    const d = 0.3989423 * Math.exp(-x * x / 2);
    const prob = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    return x > 0 ? 1 - prob : prob;
  };

  const sqrt = Math.sqrt;

  const generateSampleSizeChartData = () => {
    const data = [];
    for (let i = 0; i <= 10; i++) {
      const mde = i * 2;
      const sampleSizeForMde = calculateSampleSizeForMDE(mde);
      data.push({ mde, sampleSize: sampleSizeForMde });
    }
    console.log('Generated chart data:', data);
    return data;
  };

  const calculateSampleSizeForMDE = (mde) => {
    //if (mde === 0) return Infinity; 
    const alpha = 1 - statisticalSignificance / 100;
    const beta = 1 - statisticalPower / 100;
    const p1 = baselineConversionRate / 100;
    const p2 = p1 * (1 + mde / 100);  // Changed to relative
    const pBar = (p1 + p2) / 2;
    const zAlpha = -0.862 + sqrt(0.743 - 2.404 * Math.log(alpha));
    const zBeta = -0.862 + sqrt(0.743 - 2.404 * Math.log(beta));
    return Math.ceil(
      (Math.pow(zAlpha + zBeta, 2) * pBar * (1 - pBar) * 2) /
      Math.pow(p2 - p1, 2)
    );
  };

  const generateConfidenceIntervalChartData = () => {
  const data = [];
  const p = baselineConversionRate / 100;
  const mde = minimumDetectableEffect / 100;
  const maxSampleSize = sampleSize * 2;
  
  for (let n = 100; n <= maxSampleSize; n += maxSampleSize / 50) {
    const se = Math.sqrt(p * (1 - p) / n);
    const marginOfError = 1.96 * se;
    data.push({
      sampleSize: n,
      lowerCI: Math.max(0, p - marginOfError),
      upperCI: Math.min(1, p + marginOfError),
      baselineRate: p,
      variantRate: p * (1 + mde),  // Changed to relative
    });
  }
  return data;
};

  useEffect(() => {
    if (mode === 'sampleSize') {
      calculateSampleSize();
    } else {
      calculateSignificanceAndPower();
    }
  }, [mode, baselineConversionRate, minimumDetectableEffect, statisticalSignificance, statisticalPower, sampleSize]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-sky-blue">Power Analysis Calculator</h2>
      
      <Tabs defaultValue={mode} onValueChange={(value) => setMode(value)}>
        <TabsList className="mb-4">
          <TabsTrigger value="sampleSize">Calculate Sample Size</TabsTrigger>
          <TabsTrigger value="significanceAndPower">Calculate Significance & Power</TabsTrigger>
        </TabsList>
        
        <TabsContent value="sampleSize">
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Input Parameters</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Baseline Conversion Rate (%)</label>
                  <Slider
                    value={[baselineConversionRate]}
                    onValueChange={(value) => setBaselineConversionRate(value[0])}
                    max={100}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={baselineConversionRate}
                    onChange={(e) => setBaselineConversionRate(parseFloat(e.target.value))}
                    className="mt-1"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Minimum Detectable Effect (%)</label>
                  <Slider
                    value={[minimumDetectableEffect]}
                    onValueChange={(value) => setMinimumDetectableEffect(value[0])}
                    max={100}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={minimumDetectableEffect}
                    onChange={(e) => setMinimumDetectableEffect(parseFloat(e.target.value))}
                    className="mt-1"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Statistical Significance (%)</label>
                  <Slider
                    value={[statisticalSignificance]}
                    onValueChange={(value) => setStatisticalSignificance(value[0])}
                    max={99.9}
                    min={80}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={statisticalSignificance}
                    onChange={(e) => setStatisticalSignificance(parseFloat(e.target.value))}
                    className="mt-1"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Statistical Power (%)</label>
                  <Slider
                    value={[statisticalPower]}
                    onValueChange={(value) => setStatisticalPower(value[0])}
                    max={99.9}
                    min={50}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={statisticalPower}
                    onChange={(e) => setStatisticalPower(parseFloat(e.target.value))}
                    className="mt-1"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Results</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-lg font-semibold">Required Sample Size: {formatNumberWithCommas(sampleSize)} per variant</p>
              <p className="text-sm text-gray-600 mt-2">
                This means you need a total of {sampleSize * 2} visitors for your A/B test 
                (assuming equal split between control and variant).
              </p>
            </CardContent>
          </Card>
          
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Sample Size vs. Minimum Detectable Effect</CardTitle>
            </CardHeader>
            <CardContent>
              <div style={{ height: '400px' }}> {/* Or any other desired height */}
                <D3LineChart data={generateSampleSizeChartData()} />
              </div>
              <p className="text-sm text-gray-600 mt-2">
                This chart shows how the required sample size changes as the minimum detectable effect varies.
                A smaller minimum detectable effect requires a larger sample size to detect.
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Confidence Interval Visualization</CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={generateConfidenceIntervalChartData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="sampleSize" label={{ value: 'Sample Size per Variant', position: 'insideBottom', offset: -5 }} />
                  <YAxis label={{ value: 'Conversion Rate', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Legend />
                  <Area type="monotone" dataKey="upperCI" stroke="#8884d8" fill="#8884d8" fillOpacity={0.1} />
                  <Area type="monotone" dataKey="lowerCI" stroke="#8884d8" fill="#8884d8" fillOpacity={0.1} />
                  <Line type="monotone" dataKey="baselineRate" stroke="#8884d8" name="Baseline Rate" />
                  <Line type="monotone" dataKey="variantRate" stroke="#82ca9d" name="Variant Rate" />
                </LineChart>
              </ResponsiveContainer>
              <p className="text-sm text-gray-600 mt-2">
                This chart illustrates how the confidence interval narrows as the sample size increases.
                The blue area represents the 95% confidence interval for the baseline conversion rate.
                The green line represents the variant conversion rate (baseline + minimum detectable effect).
                When the green line is outside the blue area, we can detect a significant difference.
              </p>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="significanceAndPower">
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Input Parameters</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <label className="block mb-2">Baseline Conversion Rate (%)</label>
                  <Slider
                    value={[baselineConversionRate]}
                    onValueChange={(value) => setBaselineConversionRate(value[0])}
                    max={100}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={baselineConversionRate}
                    onChange={(e) => setBaselineConversionRate(parseFloat(e.target.value))}
                    className="mt-2"
                  />
                </div>
                <div>
                  <label className="block mb-2">Minimum Detectable Effect (%)</label>
                  <Slider
                    value={[minimumDetectableEffect]}
                    onValueChange={(value) => setMinimumDetectableEffect(value[0])}
                    max={100}
                    step={0.1}
                  />
                  <Input
                    type="number"
                    value={minimumDetectableEffect}
                    onChange={(e) => setMinimumDetectableEffect(parseFloat(e.target.value))}
                    className="mt-2"
                  />
                </div>
                <div>
                  <label className="block mb-2">Sample Size per Variant</label>
                  <Slider
                    value={[sampleSize]}
                    onValueChange={(value) => setSampleSize(value[0])}
                    max={10000}
                    step={100}
                  />
                  <Input
                    type="number"
                    value={sampleSize}
                    onChange={(e) => setSampleSize(parseInt(e.target.value))}
                    className="mt-2"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          
          <Card className="mb-6">
  <CardHeader>
    <CardTitle>Results</CardTitle>
  </CardHeader>
  <CardContent>
    <p className="text-lg font-semibold">Calculated p-value: {calculatedPValue}</p>
    <p className="text-lg font-semibold">Calculated Statistical Power: {calculatedPower}%</p>
    <p className="text-sm text-gray-600 mt-2">
      A p-value less than 0.05 is typically considered statistically significant.
      Statistical power above 80% is generally considered good.
    </p>
  </CardContent>
</Card>

<Card>
  <CardHeader>
    <CardTitle>Confidence Interval and Power Visualization</CardTitle>
  </CardHeader>
  <CardContent>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={generateConfidenceIntervalChartData()}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="sampleSize" label={{ value: 'Sample Size per Variant', position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value: 'Conversion Rate', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="upperCI" stroke="#8884d8" fill="#8884d8" fillOpacity={0.1} />
        <Area type="monotone" dataKey="lowerCI" stroke="#8884d8" fill="#8884d8" fillOpacity={0.1} />
        <Line type="monotone" dataKey="baselineRate" stroke="#8884d8" name="Baseline Rate" />
        <Line type="monotone" dataKey="variantRate" stroke="#82ca9d" name="Variant Rate" />
        <ReferenceLine x={sampleSize} stroke="red" label="Current Sample Size" />
      </LineChart>
    </ResponsiveContainer>
    <p className="text-sm text-gray-600 mt-2">
      This chart shows how the confidence interval (blue area) narrows as the sample size increases.
      The red line represents your current sample size.
      When the green line (variant rate) is outside the blue area at the red line,
      it indicates that your test can detect a significant difference at that sample size.
    </p>
  </CardContent>
</Card>
</TabsContent>
</Tabs>

<Card className="mt-8">
  <CardHeader>
    <CardTitle className="flex items-center">
      <Info className="mr-2" />
      Understanding Power Analysis
    </CardTitle>
  </CardHeader>
  <CardContent>
    <p className="mb-2">Power analysis helps you determine the sample size required for your A/B test to be statistically valid, or to understand the statistical power of your current sample size. Here's what the key terms mean:</p>
    <ul className="list-disc list-inside space-y-2">
      <li><strong>Baseline Conversion Rate:</strong> The current conversion rate of your control variant.</li>
      <li><strong>Minimum Detectable Effect:</strong> The smallest improvement in conversion rate that you want to be able to detect.</li>
      <li><strong>Statistical Significance (p-value):</strong> The probability that the observed results are due to chance. A lower p-value indicates stronger evidence against the null hypothesis.</li>
      <li><strong>Statistical Power:</strong> The probability of detecting a true effect if it exists. Higher power reduces the chance of a Type II error (false negative).</li>
      <li><strong>Sample Size:</strong> The number of visitors required in each variant of your test.</li>
      <li><strong>Confidence Interval:</strong> A range of values that is likely to contain the true population parameter. As sample size increases, this range narrows, allowing for more precise estimates.</li>
    </ul>
  </CardContent>
</Card>
</div>
);
};

export default PowerAnalysis;
