import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Welcome to A/B Test Calculator</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-sky-blue text-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-4">A/B Result Calculator</h3>
          <p className="mb-4">Calculate the results of your A/B tests and determine statistical significance.</p>
          <Link to="/ab-calculator" className="bg-white text-sky-blue px-4 py-2 rounded hover:bg-calm-green hover:text-white">
            Get Started
          </Link>
        </div>
        <div className="bg-calm-green text-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-4">Pre-test Power Analysis</h3>
          <p className="mb-4">Determine the sample size needed for your A/B test to be statistically significant.</p>
          <Link to="/power-analysis" className="bg-white text-calm-green px-4 py-2 rounded hover:bg-sky-blue hover:text-white">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;