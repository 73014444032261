import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white shadow-md mt-8">
      <div className="container mx-auto px-4 py-6 text-center text-black">
        TestingHaven.com Developed by camploanalytics LLC
      </div>
    </footer>
  );
};

export default Footer;