// src/lib/abTestUtils.js

import jstat from 'jstat';

export const calculateBayesianProbabilities = (variants) => {
  const iterations = 100000;
  const wins = new Array(variants.length).fill(0);

  for (let i = 0; i < iterations; i++) {
    const rates = variants.map(variant => 
      jstat.beta.sample(variant.conversions + 1, variant.visitors - variant.conversions + 1)
    );
    const maxRate = Math.max(...rates);
    rates.forEach((rate, index) => {
      if (rate === maxRate) wins[index]++;
    });
  }

  return variants.map((variant, index) => ({
    ...variant,
    probability: wins[index] / iterations
  }));
};

export const calculatePosteriorDistribution = (variant, points = 100) => {
  const alpha = variant.conversions + 1;
  const beta = variant.visitors - variant.conversions + 1;
  const x = Array.from({length: points}, (_, i) => i / (points - 1));
  const y = x.map(value => jstat.beta.pdf(value, alpha, beta));
  return x.map((value, index) => ({ x: value, y: y[index] }));
};